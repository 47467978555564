<template>
  <DataTable
    :headers="headers"
    :items="isLoading ? [] : items"
    :pagination="pagination"
    @click:action="handleAction"
    @click:viewAction="rowAction('view', $event)"
  >
  </DataTable>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DataTable from '../../components/base/DataTable'
export default {
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: true,
      pagination: {
        limit: 1,
        skip: 0,
        total: 0,
        totalNoPages: 0,
        pageNo: null
      }
    }
  },
  computed: {
    headers () {
      let keys = []
      keys = this.tab.viewFields.map(key => {
        return {
          text: key.label,
          value: key.dataTableID || key.schemaID,
        }
      })

      keys.push({
          text: '',
          value: 'actions',
          sortable: true
        })

      // if (this.currentTabEntity.keys) {
      //   const showFields = this.tab.viewFields.map(field => {
      //     return field.title
      //   })
        
      //   keys = this.currentTabEntity.keys.map(key => {
      //     if (showFields.includes(key.key)) {
      //       return {
      //         label: key.label,
      //         key: key.key,
      //         _style:'min-width:200px' 
      //       }
      //     }
      //   }).filter(item => item !== undefined)

      //   keys.push({
      //     label: '',
      //     key: 'show_details',
      //     sorter: false, 
      //     filter: false
      //   })
      // }
      return keys
    },
    fields () {
      let fields = this.currentTabEntity.keys

      // Filter the fields for action type
      if (this.action === 'create') {
        fields = fields.filter(field => {
          if (field.create) {
            return field
          }
        })
      }

      // Sort the fields based on order provided in enitity
      if (fields) {
        fields = fields.sort((field1, field2) => {
          return field1.order - field2.order
        })
      }

      return fields
    },
    ...mapState({
      is_loading: state => state.is_loading,
      currentTabEntity: state => state.currentTabEntity,
      items: state => state.entityRows
    })
  },
  components: {
    DataTable
  },

  methods: {
    rowAction (action, value) {
      switch (action) {
        case 'view':
          this.isLoading = true
          if (this.$route.query?.pageNo) {
            this.pagination.pageNo = this.$route.query?.pageNo === undefined ? 1 : +this.$route.query?.pageNo
            this.pagination.skip = this.$route.query?.pageNo === undefined ? 0 : (this.pagination.limit * (+this.$route.query?.pageNo - 1))
          }
          this.loadEntity({ entity: this.tab.viewModel || this.tab.entity, pagination: this.pagination })
            .then(result => {
              this.isLoading = false
              this.pagination.total = result.meta.count
              this.pagination.limit = result.meta.limit
              this.pagination.skip = result.meta.skip
              this.pagination.totalNoPages = Math.ceil(this.pagination.total / this.pagination.limit)
            })
            .catch(this.isLoading = false)
          this.$router.push({ name: 'common-tabs-content', params: { entity: this.tab.viewModel || this.tab.entity , id: value } })
      }
    },

    handleAction (action) {
      switch (action) {
        case 'reload':
          this.reloadDetails()
          break
      }
    },

    reloadDetails () {
      this.loadCurrentEntityTabs(this.$route.params)
    },

    loadCurrentEntityTabs (params) {
      this.isLoading = true
      const queryParam = Object.keys(params).reduce((final ,param) => {
        if (param === 'entity') {
          return { ...final, entity: this.tab.entity }
        } else {
          return {
            ...final,
            keys: this.tab.queryFilter,
            value: params[param]
          }
        }
      }, {})

      this.pagination.pageNo = this.$route.query?.pageNo === undefined ? 1 : +this.$route.query?.pageNo
      this.pagination.skip = this.$route.query?.pageNo === undefined ? 0 : (this.pagination.limit * (+this.$route.query?.pageNo - 1))

      this.loadEntity({ entity: queryParam.entity, tab: this.tab, keys: queryParam.keys, value: queryParam.value, pagination: this.pagination })
        .then(result => {
          this.isLoading = false
          this.pagination.total = result.meta.count
          this.pagination.limit = result.meta.limit
          this.pagination.skip = result.meta.skip
          this.pagination.totalNoPages = Math.ceil(this.pagination.total / this.pagination.limit)
        })
        .catch(this.isLoading = false)
    },
    ...mapActions([
      'loadEntity'
    ])
  },
  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler: 'loadCurrentEntityTabs'
    },
    'tab': {
      deep: true,
      immediate: true,
      handler () {
        this.loadCurrentEntityTabs(this.$route.params)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
